/*  I M A G E S  */

figure {
  @include user-select;
  width: 100%;
  margin: 0;
  padding: 0;
  // Responsive images with forced aspect ratio.
  &.forced-ratio {
  	height: 0;
  	position: relative;
    // Padding bottom defines aspect ratio.
    padding-bottom: 66%;
  	overflow: hidden;
    img {
    	width: 100%;
    	height: 100%;
    	position: absolute;
    	top: 0;
    	right: 0;
    	bottom: 0;
    	left: 0;
    	object-fit: cover;
    	&.fade {
      	// Stops overlay text moving the image frame during hover transition in Vivaldi at default zoom and Safari font rendering bug in the overlay during transition
      	transform: translate3d(0, 0, 0);
      	filter: saturate(0.2) brightness(0.75) opacity(0.85);
        will-change: all;
        transition: all .2s;
    	}
    }
    &:hover img.fade {
      filter: saturate(1) brightness(1) opacity(1);
    }
  }
  img {
    width: 100%;
  }
  figcaption {
    width: 100%;
    p {
      margin: 0;
      padding: 0;
      font-size: 0.9rem;
      font-weight: normal;
      text-align: right;
      color: $grey;
    }
    &.overlay {
      @include heading-font(2em, normal, 1.5);
      width: auto;
      position: absolute;
    	top: 50%;
    	left: 50%;
    	transform: translate(-50%, -50%);
      color: $white;
      // Stops overlay text moving during hover transition in Vivaldi, Chrome and Opera 
    	backface-visibility: hidden;
    }
  }
}
