/*  M A P  */

.mono-locations {
  @include user-select;
  width: 100%;
  height: 65vh;
  margin-top: $space-root;
  // Mapbox style overrides.
  .mapboxgl-ctrl-group {
    border-radius: 0;
    box-shadow: none;
    .mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-in {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAyMCAyMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEuNDE0MjE7Ij48cGF0aCBkPSJNMTEsOWwzLDBsMCwybC0zLDBsMCwzbC0yLDBsMCwtM2wtMywwbDAsLTJsMywwbDAsLTNsMiwwbDAsM1oiIHN0eWxlPSJmaWxsOiMzMzM7Ii8+PC9zdmc+");
    }
    .mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-out {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAyMCAyMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEuNDE0MjE7Ij48cmVjdCB4PSI2IiB5PSI5IiB3aWR0aD0iOCIgaGVpZ2h0PSIyIiBzdHlsZT0iZmlsbDojMzMzOyIvPjwvc3ZnPg==");
    }
  }
  .mapboxgl-ctrl-attrib {
    font-family: 'FF DIN', 'Avenir Next', sans-serif;
    a {
      border-bottom: 0 !important;
      text-decoration: none !important;
    }
    .mapbox-improve-map {
      display: none;
    }
  }
  .mapboxgl-popup {
    max-width: 75vw;
    z-index: 3;
    &.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
      border-bottom-color: $white-trans;
    }
    &.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
      border-left-color: $white-trans;
    }
    &.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
      border-top-color: $white-trans;
    }
    &.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
      border-right-color: $white-trans;
    }
    .mapboxgl-popup-content {
      // Magic numbers to make the padding equal pixels on all sides!
      padding: 1rem 1.15625rem 1.3125rem;
      background: $white-trans;
      border-radius: 0;
      font-family: 'FF DIN', 'Avenir Next', sans-serif;
      font-weight: normal;
      font-size: 0.9rem;
      h4 {
        @include user-select(auto);
        margin: 0;
        padding-bottom: 0.375rem;
        line-height: 1;
      }
      p {
        @include user-select(auto);
        margin: 0;
        line-height: 1.5;
      }
    }
  }
}
