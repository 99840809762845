/*  M I X I N S  */

// Defines heading font, with variable size, weight and line-height parameters and fixed letter-spacing.
@mixin heading-font($size, $weight, $line-height) {
  font: {
    family: 'FF DIN', 'Avenir Next', sans-serif;
    size: $size;
    weight: $weight;
  }
  line-height: $line-height;
}

// Defines body font, with variable size, weight and line-height parameters.
@mixin body-font($size, $weight, $line-height) {
  font: {
    family: 'FF DIN', 'Avenir Next', sans-serif;
    size: $size;
    weight: $weight;
  }
  line-height: $line-height;
}

// Defines media query breakpoints for responsive design.
// Define breakpoints here... (ems is better)
$breakpoints: (
  'medium': 50em, // 800px
  'large': 70em // 1120px
);

@mixin viewport($breakpoint) { 
  // Gets the corresponding breakpoint value from the $breakpoints map.
  $value: map-get($breakpoints, $breakpoint);
  
  // Applies the appropriate media query.
  @media (min-width: $value) {
    @content;
  }  
}

// Prevents user from selecting text.
@mixin user-select($select: none){
  user-select: $select;
}

// For styling <a> tags.
@mixin link-style {
  padding-bottom: $link;
  border-bottom: 1px solid currentColor;
}
