/*  H E A D E R  */

header.navigation-bar {
  @include user-select;
  display: flex;
  flex-direction: column;
	width: 100%;
	height: $header;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	background: $white;
	color: $black;
	z-index: 11;
	will-change: transform;
	transition: transform .3s linear, color .4s;	
	.icon svg {
    will-change: all;
    transition: all .4s;
	}
  &.navigation-bar--pinned {
  	transform: translateY(0%);
  }
  &.navigation-bar--unpinned {
  	transform: translateY(-100%);
  	pointer-events: none;
  }
  &.navigation-open {
    color: $white;
    // Overlays a background on the header with the same colour and transparency as the rest of the navigation.
    .navigation-bar--overlay { 
      opacity: 1;
    }
    .icon svg {
      fill: #FFF;
      color: #FFF;
    }
  }
  .navigation-bar--overlay {
    opacity: 0;
    background: $black-trans;
    height: $header;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transition: opacity .4s;    
  }
  .navigation-bar--container {
  	display: flex;
  	justify-content: space-between;
  	align-items: center;
  	width: auto;
  	height: 100%;
  	position: relative;
  	top: 0;
  	right: 0;
  	left: 0;
    padding: 0 ($space / 2);
    @include viewport(medium) {
      padding: 0 $space-root;
    }
    .navigation-bar--logo {
    	flex-shrink: 0;
    }
    .navigation-bar--hamburger {
    	display: block;
    	position: relative;
    	padding: 0;
    	width: 36px;
    	height: 28px;
    	cursor: pointer;
    	flex-shrink: 0;
      &:focus {
      	outline: none;
      }
      span,
      span::before,
      span::after {
      	display: block;
      	position: absolute;
      	left: 0;
      	width: 100%;
      	height: 4px;
      	background: $icon-dark;
      	content: "";
      }
      span {
        // Smooths animation by forcing hardware acceleration.
        backface-visibility: hidden;
      	top: 12px;
      	will-change: all;
      	transition: transform .2s, background .4s;
      }
      span::before {
      	top: -12px;
      	transition: opacity 0s, top .2s .2s, background .4s;
      }
      span::after {
      	bottom: -12px;
      	transition: transform .2s, bottom .2s .2s, background .4s;
      }
      // Active state.
      &.active span,
      &.active span::before,
      &.active span::after {
        background: $icon-light;
      }
      &.active span {
      	transform: rotate(45deg);
      	transition: transform .2s .2s, background .4s;
      }
      &.active span::before {
      	top: 0;
      	opacity: 0;
      	transition: top .2s, opacity 0s .2s, background .4s;
      }
      &.active span::after {
      	bottom: 0;
      	transform: rotate(-90deg);
      	transition: bottom .2s, transform .2s .2s, background .4s;
      }
    }
  }
}
