/*  V A R I A B L E S  */

// Header height.
$header: 4.375rem;   // 4.375rem = 70px.

// Sets main content grid spacing, footer icon grid spacing, etc.
$space: 1.875em;   // 1.875em = 30px (when 1em = 16px).
$space-root: 1.875rem;   // Root relative space using rems; 1.875rem = 30px.

// Link underline spacing.
$link: 0.0625em;  // 0.0625em = 1px.
