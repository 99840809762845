/*  F O O T E R  */

footer {
  @include user-select;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: ($space * 3);
	padding: $space;
	text-align: center;
	> * {
  	margin: 0 0 $space;
  	font-size: 1rem;
  	&:last-child {
    	margin: $space 0 0;
  	}
  }
  a {
  	@include link-style;
  }
  ul {
  	display: flex;
  	flex-flow: row wrap;
  	justify-content: center;
  	align-items: center;
    padding: 0;
    margin-bottom: (-$space);
    li {
    	display: block;
    	width: auto;
    	padding: 0 ($space / 1.25) $space;
    }
  }
  & .footer--partners p {
    padding-bottom: ($space / 2);
  }
  & .footer--sponsors p {
    padding-bottom: ($space / 2);
  }
}
