/*  L A Y O U T  */

// Single column layout.
.single-column--container {
  display: flex;
  height: auto;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  .single-column {
    width: 100%;
    @include viewport(medium) {
      width: 66.667%;
    }
  }
}
// Grid layout.
.grid {
	display: flex;
	height: auto;
	margin: (-$space-root) 0 0 (-$space-root);
	flex-flow: row wrap;
	justify-content: flex-start;
  .grid-cell {
  	flex: 1;
  	padding: $space-root 0 0 $space-root;
  	overflow: hidden;
    &-full {
    	flex: 0 0 100%;
    	// For IE 10-11 need to set max-width for flex children with a flex basis width, otherwise IE assumes the content box model and adds padding on top of the calculated width.
    	max-width: 100%;
    }
    &-half {
    	flex: 0 0 100%;
    	max-width: 100%;
    }
    &-third {
    	flex: 0 0 100%;
    	max-width: 100%;
    }
    &-quarter {
    	flex: 0 0 100%;
    	max-width: 100%;
    }
  }
  @include viewport(medium) {
  	margin: (-$space-root) 0 0 (-$space-root);
    .grid-cell {
     	padding: $space-root 0 0 $space-root;
      &-full {
      	flex: 0 0 100%;
      	max-width: 100%;
      }
      &-half {
      	flex: 0 0 50%;
      	max-width: 50%;
      }
      &-third {
      	flex: 0 0 50%;
      	max-width: 50%;
      }
      &-quarter {
      	flex: 0 0 50%;
      	max-width: 50%;
      }
  	}
	}
	@include viewport(large) {
  	margin: (-$space-root) 0 0 (-$space-root);
    .grid-cell {
     	padding: $space-root 0 0 $space-root;
      &-full {
      	flex: 0 0 100%;
      	max-width: 100%;
      }
      &-half {
      	flex: 0 0 50%;
      	max-width: 50%;
      }
      &-third {
        // Use calc to get the browser to determine the number of decimal places.
      	flex: 0 0 percentage((1 / 3));
      	max-width: percentage((1 / 3));
      }
      &-quarter {
      	flex: 0 0 25%;
      	max-width: 25%;
      }
  	}
	}
}
