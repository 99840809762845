/*  I C O N S  */

// Creates a responsive container for svg icons, and sets fill colour.
.icon {
	height: 0;
	position: relative;
  // Specific classes set width for each icon and automatically adjusts height from aspect ratio. Responsive icon sizing applied via padding-bottom hack on the div containers.
  // MONO logo
  &.logo {
  	// Change width to adjust size of logo.
  	width: 8em;
  	// Calculate height from aspect ratio (h / w * 100%).
  	padding-bottom: (48 / 177 * 100%);
  }
  // UI icons
  &.email {
    width: 2.25em;
    padding-bottom: (500 / 500 * 100%);
  }
  &.facebook {
    width: 2.25em;
    padding-bottom: (500 / 500 * 100%);
  }
  &.instagram {
    width: 2.25em;
    padding-bottom: (504 / 504 * 100%);
  }
  &.scroll {
  	width: 2.5em;
  	padding-bottom: (40 / 40 * 100%);
  	svg {
    	fill: $icon-light;
    	color: $icon-light;
  	}
  }
  // Partner logos
  &.lundskonsthall {
  	width: 10em;
  	padding-bottom: (36 / 336 * 100%);
  }
  &.skissernas {
  	width: 15em;
  	padding-bottom: (57 / 748 * 100%);
  }
  &.stenkrossen {
    width: 10em;
    padding-bottom: (124 / 1134 * 100%);
  }
  // Sponsor logos
  &.kulturlund {
  	width: 7.75em;
  	padding-bottom: (248 / 489 * 100%);
  }
	svg {
  	width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: visible;
  	// Default colour of icons.
    fill: $icon-dark;
    // Fix for Firefox overriding fill colour.
    color: $icon-dark;
	}
}
