/*  L I N K S  */

a {
  // Use of !important stops Firefox from adding dotted outline when focused.
	outline: none !important;
	text-decoration: none;
	color: inherit;
}
article a {
	color: inherit;
}
