/*  B A S E  */

body {
	background: $white;
	// Removes tap highlight.
	-webkit-tap-highlight-color: transparent;
	img {
  	-webkit-user-drag: none;
  	user-select: none;
	}
	main.content {
  	// To ensure enough clear space from footer when minimal content.
	  min-height: calc(56vh - #{$header});
	  margin-top: $header;
	  .content-wrapper {
  	  margin: 0 auto;
  	  padding: 0 ($space / 2);
  	  @include viewport(medium) {
    	  padding: 0 $space-root;
      }
      .sub-navigation {
        @include user-select;
        margin-bottom: ($space / 2);
        font-weight: bold;
      }
  	  a {
    	  @include link-style;
    	  &.no-link-style {
      	  padding-bottom: 0;
      	  border-bottom: none;
    	  }
        &.button {
          @include user-select;
          display: block;
          width: 100%;
          max-width: 12rem;
          margin: 1.5em 0;
          padding: 0.4em 0.5em 0.5em;
          border: 3px solid $black;
          text-transform: uppercase;
          text-align: center;
          font-weight: bold;
          font-size: 1.75rem;
          &:hover {
            background: $black;
            color: $white;
          }
        }
  	  }
    	ul {
      	padding-left: 0;
      	list-style: none;
    	}
    	ul li:before {
      	display: table-cell;
      	content: '–';
      	padding-right: $space/3;
    	}
    	ul li {
      	display: table;
    	}
      span.full {
        @include user-select;
        display: block;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        text-align: left;
        font-weight: bold;
        font-size: 1.75rem;
      }
	  }
	}
	// Stops scrolling on body when navigation open.
	&.navigation-open {  	
    max-height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    overflow-y: scroll;
	}
}
// Button hover effect.
.hover-enabled .content-wrapper {
  a.button {
    transition: all .2s;
    will-change: all;
  }
}
// Stops jQuery bug if clicking directly on svg element. See: https://css-tricks.com/gotchas-on-getting-svg-into-production/
svg {
  pointer-events: none;
}
