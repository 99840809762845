@charset "UTF-8";
/*! sanitize.css v4.1.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Display definitions
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 * 2. Add the correct display in IE.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  /* 1 */
  display: block; }

/**
 * Add the correct display in IE 9-.
 */
audio,
canvas,
progress,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Add the correct display in IE 10-.
 * 1. Add the correct display in IE.
 */
template,
[hidden] {
  display: none; }

/* Elements of HTML (https://www.w3.org/TR/html5/semantics.html)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: inherit;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Add a flattened line height in all browsers (opinionated).
 * 4. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  font-family: sans-serif;
  /* 3 */
  line-height: 1.5;
  /* 3 */
  -ms-text-size-adjust: 100%;
  /* 4 */
  -webkit-text-size-adjust: 100%;
  /* 5 */ }

/* Sections (https://www.w3.org/TR/html5/sections.html)
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Correct the font sizes and margins on `h1` elements within
 * `section` and `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: .67em 0; }

/* Grouping content (https://www.w3.org/TR/html5/grouping-content.html)
   ========================================================================== */
/**
 * 1. Correct font sizing inheritance and scaling in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * 1. Correct the height in Firefox.
 * 2. Add visible overflow in Edge and IE.
 */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none; }

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Add a bordered underline effect in all browsers.
 * 2. Remove text decoration in Firefox 40+.
 */
abbr[title] {
  border-bottom: 1px dotted;
  /* 1 */
  text-decoration: none;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * Add the correct colors in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000; }

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline; }

/**
 * Correct the font size in all browsers.
 */
small {
  font-size: 83.3333%; }

/**
 * Change the positioning on superscript and subscript elements
 * in all browsers (opinionated).
 * 1. Correct the font size in all browsers.
 */
sub,
sup {
  font-size: 83.3333%;
  /* 1 */
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

/* Embedded content (https://www.w3.org/TR/html5/embedded-content-0.html)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Links (https://www.w3.org/TR/html5/links.html#links)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove the gaps in underlines in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * Remove the outline when hovering in all browsers (opinionated).
 */
a:hover {
  outline-width: 0; }

/* Tabular data (https://www.w3.org/TR/html5/tabular-data.html)
   ========================================================================== */
/*
 * Remove border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* transform-style:  (https://www.w3.org/TR/html5/forms.html)
   ========================================================================== */
/**
 * 1. Remove the default styling in all browsers (opinionated).
 * 3. Remove the margin in Firefox and Safari.
 */
button,
input,
select,
textarea {
  background-color: transparent;
  /* 1 */
  border-style: none;
  /* 1 */
  color: inherit;
  /* 1 */
  font-size: 1em;
  /* 1 */
  margin: 0;
  /* 3 */ }

/**
 * Correct the overflow in IE.
 * 1. Correct the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance in Edge, Firefox, and IE.
 * 1. Remove the inheritance in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent the WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Correct the focus styles unset by the previous rule.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * Correct the border, margin, and padding in all browsers.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: .35em .625em .75em; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Remove the vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */ }

/**
 * Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  padding: 0; }

/**
 * Correct the cursor style on increment and decrement buttons in Chrome.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari for OS X.
 */
::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Correct the text style on placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: .54; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/* WAI-ARIA (https://www.w3.org/TR/html5/dom.html#wai-aria)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default; }

/* User interaction (https://www.w3.org/TR/html5/editing.html)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation; }

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[hidden][aria-hidden="false"] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

[hidden][aria-hidden="false"]:focus {
  clip: auto; }

/*  S I T E  C O L O U R S  */
/*  W E B  F O N T S  */
/* This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: http://www.fontshop.com */
@font-face {
  font-family: 'FF DIN';
  src: url("../_fonts/1381398/7cec9587-8ab7-442c-941d-3361ca9b5fa0.woff2") format("woff2"), url("../_fonts/1381398/a5c0bb07-797b-411b-ab40-0942c7618db8.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FF DIN';
  src: url("../_fonts/1448152/673d0af4-f3a4-4a8b-8d7f-24aa5c8a4a2c.woff2") format("woff2"), url("../_fonts/1448152/ac55f8b3-1f99-484b-a876-7314bb62ae8d.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

/*  V A R I A B L E S  */
/*  M I X I N S  */
/*  B A S E  */
body {
  background: white;
  -webkit-tap-highlight-color: transparent; }
  body img {
    -webkit-user-drag: none;
    user-select: none; }
  body main.content {
    min-height: calc(56vh - 4.375rem);
    margin-top: 4.375rem; }
    body main.content .content-wrapper {
      margin: 0 auto;
      padding: 0 0.9375em; }
      @media (min-width: 50em) {
        body main.content .content-wrapper {
          padding: 0 1.875rem; } }
      body main.content .content-wrapper .sub-navigation {
        user-select: none;
        margin-bottom: 0.9375em;
        font-weight: bold; }
      body main.content .content-wrapper a {
        padding-bottom: 0.0625em;
        border-bottom: 1px solid currentColor; }
        body main.content .content-wrapper a.no-link-style {
          padding-bottom: 0;
          border-bottom: none; }
        body main.content .content-wrapper a.button {
          user-select: none;
          display: block;
          width: 100%;
          max-width: 12rem;
          margin: 1.5em 0;
          padding: 0.4em 0.5em 0.5em;
          border: 3px solid black;
          text-transform: uppercase;
          text-align: center;
          font-weight: bold;
          font-size: 1.75rem; }
          body main.content .content-wrapper a.button:hover {
            background: black;
            color: white; }
      body main.content .content-wrapper ul {
        padding-left: 0;
        list-style: none; }
      body main.content .content-wrapper ul li:before {
        display: table-cell;
        content: '–';
        padding-right: 0.625em; }
      body main.content .content-wrapper ul li {
        display: table; }
      body main.content .content-wrapper span.full {
        user-select: none;
        display: block;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        text-align: left;
        font-weight: bold;
        font-size: 1.75rem; }
  body.navigation-open {
    max-height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    overflow-y: scroll; }

.hover-enabled .content-wrapper a.button {
  transition: all .2s;
  will-change: all; }

svg {
  pointer-events: none; }

/*  G E N E R A L  */
::selection {
  background: black;
  color: white; }

/*  I C O N S  */
.icon {
  height: 0;
  position: relative; }
  .icon.logo {
    width: 8em;
    padding-bottom: 27.11864%; }
  .icon.email {
    width: 2.25em;
    padding-bottom: 100%; }
  .icon.facebook {
    width: 2.25em;
    padding-bottom: 100%; }
  .icon.instagram {
    width: 2.25em;
    padding-bottom: 100%; }
  .icon.scroll {
    width: 2.5em;
    padding-bottom: 100%; }
    .icon.scroll svg {
      fill: white;
      color: white; }
  .icon.lundskonsthall {
    width: 10em;
    padding-bottom: 10.71429%; }
  .icon.skissernas {
    width: 15em;
    padding-bottom: 7.62032%; }
  .icon.stenkrossen {
    width: 10em;
    padding-bottom: 10.93474%; }
  .icon.kulturlund {
    width: 7.75em;
    padding-bottom: 50.71575%; }
  .icon svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: visible;
    fill: black;
    color: black; }

/*  I M A G E S  */
figure {
  user-select: none;
  width: 100%;
  margin: 0;
  padding: 0; }
  figure.forced-ratio {
    height: 0;
    position: relative;
    padding-bottom: 66%;
    overflow: hidden; }
    figure.forced-ratio img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover; }
      figure.forced-ratio img.fade {
        transform: translate3d(0, 0, 0);
        filter: saturate(0.2) brightness(0.75) opacity(0.85);
        will-change: all;
        transition: all .2s; }
    figure.forced-ratio:hover img.fade {
      filter: saturate(1) brightness(1) opacity(1); }
  figure img {
    width: 100%; }
  figure figcaption {
    width: 100%; }
    figure figcaption p {
      margin: 0;
      padding: 0;
      font-size: 0.9rem;
      font-weight: normal;
      text-align: right;
      color: #4d4d4d; }
    figure figcaption.overlay {
      font-family: 'FF DIN', 'Avenir Next', sans-serif;
      font-size: 2em;
      font-weight: normal;
      line-height: 1.5;
      width: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      backface-visibility: hidden; }

/*  T Y P O G R A P H Y  */
body {
  font-family: 'FF DIN', 'Avenir Next', sans-serif;
  font-weight: normal;
  font-size: 1.25em; }

h1 {
  font-family: 'FF DIN', 'Avenir Next', sans-serif;
  font-size: 2em;
  font-weight: bold;
  line-height: 1.5;
  text-transform: uppercase; }

h2 {
  font-family: 'FF DIN', 'Avenir Next', sans-serif;
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.5;
  text-transform: uppercase; }

h3 {
  font-family: 'FF DIN', 'Avenir Next', sans-serif;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.5;
  text-transform: uppercase; }

h4 {
  font-family: 'FF DIN', 'Avenir Next', sans-serif;
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.5; }

h5 {
  font-family: 'FF DIN', 'Avenir Next', sans-serif;
  font-size: 0.625em;
  font-weight: 400;
  line-height: 1.5; }

h6 {
  font-family: 'FF DIN', 'Avenir Next', sans-serif;
  font-size: 0.625em;
  font-weight: 400;
  line-height: 1.5; }

/*  M A P  */
.mono-locations {
  user-select: none;
  width: 100%;
  height: 65vh;
  margin-top: 1.875rem; }
  .mono-locations .mapboxgl-ctrl-group {
    border-radius: 0;
    box-shadow: none; }
    .mono-locations .mapboxgl-ctrl-group .mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-in {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAyMCAyMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEuNDE0MjE7Ij48cGF0aCBkPSJNMTEsOWwzLDBsMCwybC0zLDBsMCwzbC0yLDBsMCwtM2wtMywwbDAsLTJsMywwbDAsLTNsMiwwbDAsM1oiIHN0eWxlPSJmaWxsOiMzMzM7Ii8+PC9zdmc+"); }
    .mono-locations .mapboxgl-ctrl-group .mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-out {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAyMCAyMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEuNDE0MjE7Ij48cmVjdCB4PSI2IiB5PSI5IiB3aWR0aD0iOCIgaGVpZ2h0PSIyIiBzdHlsZT0iZmlsbDojMzMzOyIvPjwvc3ZnPg=="); }
  .mono-locations .mapboxgl-ctrl-attrib {
    font-family: 'FF DIN', 'Avenir Next', sans-serif; }
    .mono-locations .mapboxgl-ctrl-attrib a {
      border-bottom: 0 !important;
      text-decoration: none !important; }
    .mono-locations .mapboxgl-ctrl-attrib .mapbox-improve-map {
      display: none; }
  .mono-locations .mapboxgl-popup {
    max-width: 75vw;
    z-index: 3; }
    .mono-locations .mapboxgl-popup.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
      border-bottom-color: rgba(255, 255, 255, 0.9); }
    .mono-locations .mapboxgl-popup.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
      border-left-color: rgba(255, 255, 255, 0.9); }
    .mono-locations .mapboxgl-popup.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
      border-top-color: rgba(255, 255, 255, 0.9); }
    .mono-locations .mapboxgl-popup.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
      border-right-color: rgba(255, 255, 255, 0.9); }
    .mono-locations .mapboxgl-popup .mapboxgl-popup-content {
      padding: 1rem 1.15625rem 1.3125rem;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 0;
      font-family: 'FF DIN', 'Avenir Next', sans-serif;
      font-weight: normal;
      font-size: 0.9rem; }
      .mono-locations .mapboxgl-popup .mapboxgl-popup-content h4 {
        user-select: auto;
        margin: 0;
        padding-bottom: 0.375rem;
        line-height: 1; }
      .mono-locations .mapboxgl-popup .mapboxgl-popup-content p {
        user-select: auto;
        margin: 0;
        line-height: 1.5; }

/*  L A Y O U T  */
.single-column--container {
  display: flex;
  height: auto;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center; }
  .single-column--container .single-column {
    width: 100%; }
    @media (min-width: 50em) {
      .single-column--container .single-column {
        width: 66.667%; } }

.grid {
  display: flex;
  height: auto;
  margin: -1.875rem 0 0 -1.875rem;
  flex-flow: row wrap;
  justify-content: flex-start; }
  .grid .grid-cell {
    flex: 1;
    padding: 1.875rem 0 0 1.875rem;
    overflow: hidden; }
    .grid .grid-cell-full {
      flex: 0 0 100%;
      max-width: 100%; }
    .grid .grid-cell-half {
      flex: 0 0 100%;
      max-width: 100%; }
    .grid .grid-cell-third {
      flex: 0 0 100%;
      max-width: 100%; }
    .grid .grid-cell-quarter {
      flex: 0 0 100%;
      max-width: 100%; }
  @media (min-width: 50em) {
    .grid {
      margin: -1.875rem 0 0 -1.875rem; }
      .grid .grid-cell {
        padding: 1.875rem 0 0 1.875rem; }
        .grid .grid-cell-full {
          flex: 0 0 100%;
          max-width: 100%; }
        .grid .grid-cell-half {
          flex: 0 0 50%;
          max-width: 50%; }
        .grid .grid-cell-third {
          flex: 0 0 50%;
          max-width: 50%; }
        .grid .grid-cell-quarter {
          flex: 0 0 50%;
          max-width: 50%; } }
  @media (min-width: 70em) {
    .grid {
      margin: -1.875rem 0 0 -1.875rem; }
      .grid .grid-cell {
        padding: 1.875rem 0 0 1.875rem; }
        .grid .grid-cell-full {
          flex: 0 0 100%;
          max-width: 100%; }
        .grid .grid-cell-half {
          flex: 0 0 50%;
          max-width: 50%; }
        .grid .grid-cell-third {
          flex: 0 0 33.33333%;
          max-width: 33.33333%; }
        .grid .grid-cell-quarter {
          flex: 0 0 25%;
          max-width: 25%; } }

/*  L I N K S  */
a {
  outline: none !important;
  text-decoration: none;
  color: inherit; }

article a {
  color: inherit; }

/*  H E A D E R  */
header.navigation-bar {
  user-select: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 4.375rem;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: white;
  color: black;
  z-index: 11;
  will-change: transform;
  transition: transform .3s linear, color .4s; }
  header.navigation-bar .icon svg {
    will-change: all;
    transition: all .4s; }
  header.navigation-bar.navigation-bar--pinned {
    transform: translateY(0%); }
  header.navigation-bar.navigation-bar--unpinned {
    transform: translateY(-100%);
    pointer-events: none; }
  header.navigation-bar.navigation-open {
    color: white; }
    header.navigation-bar.navigation-open .navigation-bar--overlay {
      opacity: 1; }
    header.navigation-bar.navigation-open .icon svg {
      fill: #FFF;
      color: #FFF; }
  header.navigation-bar .navigation-bar--overlay {
    opacity: 0;
    background: rgba(0, 0, 0, 0.92);
    height: 4.375rem;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transition: opacity .4s; }
  header.navigation-bar .navigation-bar--container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    height: 100%;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    padding: 0 0.9375em; }
    @media (min-width: 50em) {
      header.navigation-bar .navigation-bar--container {
        padding: 0 1.875rem; } }
    header.navigation-bar .navigation-bar--container .navigation-bar--logo {
      flex-shrink: 0; }
    header.navigation-bar .navigation-bar--container .navigation-bar--hamburger {
      display: block;
      position: relative;
      padding: 0;
      width: 36px;
      height: 28px;
      cursor: pointer;
      flex-shrink: 0; }
      header.navigation-bar .navigation-bar--container .navigation-bar--hamburger:focus {
        outline: none; }
      header.navigation-bar .navigation-bar--container .navigation-bar--hamburger span,
      header.navigation-bar .navigation-bar--container .navigation-bar--hamburger span::before,
      header.navigation-bar .navigation-bar--container .navigation-bar--hamburger span::after {
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 4px;
        background: black;
        content: ""; }
      header.navigation-bar .navigation-bar--container .navigation-bar--hamburger span {
        backface-visibility: hidden;
        top: 12px;
        will-change: all;
        transition: transform .2s, background .4s; }
      header.navigation-bar .navigation-bar--container .navigation-bar--hamburger span::before {
        top: -12px;
        transition: opacity 0s, top .2s .2s, background .4s; }
      header.navigation-bar .navigation-bar--container .navigation-bar--hamburger span::after {
        bottom: -12px;
        transition: transform .2s, bottom .2s .2s, background .4s; }
      header.navigation-bar .navigation-bar--container .navigation-bar--hamburger.active span,
      header.navigation-bar .navigation-bar--container .navigation-bar--hamburger.active span::before,
      header.navigation-bar .navigation-bar--container .navigation-bar--hamburger.active span::after {
        background: white; }
      header.navigation-bar .navigation-bar--container .navigation-bar--hamburger.active span {
        transform: rotate(45deg);
        transition: transform .2s .2s, background .4s; }
      header.navigation-bar .navigation-bar--container .navigation-bar--hamburger.active span::before {
        top: 0;
        opacity: 0;
        transition: top .2s, opacity 0s .2s, background .4s; }
      header.navigation-bar .navigation-bar--container .navigation-bar--hamburger.active span::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom .2s, transform .2s .2s, background .4s; }

/*  N A V I G A T I O N  */
.navigation {
  user-select: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.92);
  color: white;
  text-transform: uppercase;
  opacity: 0;
  pointer-events: none;
  will-change: opacity;
  transition: opacity .4s;
  z-index: 10; }
  .navigation.navigation-open {
    opacity: 1;
    pointer-events: auto; }
  .navigation ::-webkit-scrollbar {
    width: 0; }
  .navigation .navigation-inner {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }
    .navigation .navigation-inner > ul {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      margin: 4.375rem auto 0;
      padding: 0 0 4.375rem; }
      .navigation .navigation-inner > ul li {
        font-size: 2.1em;
        letter-spacing: 0.075em;
        font-weight: normal;
        margin: 0.63rem 0; }
        @media (min-width: 50em) {
          .navigation .navigation-inner > ul li {
            font-size: 2.5em;
            margin: 0.75rem 0; } }
  .navigation a {
    outline: none;
    text-decoration: none;
    color: inherit; }

/*  H E R O  U N I T  */
.hero-unit--container {
  user-select: none;
  width: 100%;
  height: calc(100vh - 4.375rem);
  min-height: 400px;
  overflow: hidden;
  margin: 0 auto;
  position: relative; }
  .hero-unit--container .hero-unit {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: white; }
    .hero-unit--container .hero-unit img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
      .hero-unit--container .hero-unit img.fade {
        filter: saturate(0.9) brightness(0.8); }
    .hero-unit--container .hero-unit .hero-unit--overlay {
      display: inline-block;
      width: auto;
      min-width: 18.75rem;
      max-width: 80vw;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      backface-visibility: hidden;
      will-change: all;
      transition: opacity .25s, top .25s;
      opacity: 1; }
      .hero-unit--container .hero-unit .hero-unit--overlay.hidden {
        opacity: 0;
        top: 80%; }
      .hero-unit--container .hero-unit .hero-unit--overlay span {
        display: block;
        font-size: 1.75rem;
        font-size: 1.75rem; }
      .hero-unit--container .hero-unit .hero-unit--overlay > :last-child {
        margin-top: 1.875em; }
      .hero-unit--container .hero-unit .hero-unit--overlay .hero-unit--logo {
        margin-top: -2.25rem;
        margin-left: -0.25rem;
        font-size: 5rem;
        font-weight: bold;
        text-transform: uppercase;
        line-height: normal; }
      .hero-unit--container .hero-unit .hero-unit--overlay .hero-unit--subhead {
        margin-top: -0.4375rem;
        margin-bottom: -0.4375rem;
        line-height: 1.5; }
      .hero-unit--container .hero-unit .hero-unit--overlay .hero-unit--info {
        line-height: 1.5;
        margin-bottom: -0.125rem; }
    .hero-unit--container .hero-unit .hero-unit--scroll-button {
      position: absolute;
      bottom: 5vh;
      left: 50%;
      transform: translateX(-50%);
      will-change: all;
      transition: opacity .4s;
      opacity: 1; }
      .hero-unit--container .hero-unit .hero-unit--scroll-button.hidden {
        pointer-events: none;
        opacity: 0; }

.hover-enabled .hero-unit--container .hero-unit--scroll-button .scroll {
  will-change: all;
  transition: transform .2s; }

.hover-enabled .hero-unit--container .hero-unit--scroll-button:hover .scroll {
  transform: scale(1.1); }

/*  F O O T E R  */
footer {
  user-select: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5.625em;
  padding: 1.875em;
  text-align: center; }
  footer > * {
    margin: 0 0 1.875em;
    font-size: 1rem; }
    footer > *:last-child {
      margin: 1.875em 0 0; }
  footer a {
    padding-bottom: 0.0625em;
    border-bottom: 1px solid currentColor; }
  footer ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: -1.875em; }
    footer ul li {
      display: block;
      width: auto;
      padding: 0 1.5em 1.875em; }
  footer .footer--partners p {
    padding-bottom: 0.9375em; }
  footer .footer--sponsors p {
    padding-bottom: 0.9375em; }
