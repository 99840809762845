/*  H E R O  U N I T  */

.hero-unit--container {
  @include user-select;
	width: 100%;
	// Height set to viewport height minus navigation bar.
	height: calc(100vh - #{$header});
	// Minimum height to avoid squashing hero unit in landscape view on small screens.
	min-height: 400px;
	overflow: hidden;
	margin: 0 auto;
	position: relative;
  .hero-unit {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
   	align-items: center;
  	width: 100%;
  	height: 100%;
  	color: $white;
    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    	width: 100%;
    	height: 100%;
    	object-fit: cover;
    	&.fade {
      	filter: saturate(0.9) brightness(0.8);
    	}
    }
    .hero-unit--overlay {
      display: inline-block;
      width: auto;
      min-width: 18.75rem;
      max-width: 80vw;
      position: absolute;
    	top: 50%;
    	left: 50%;
    	transform: translate(-50%, -50%);
      // Smooths animation by forcing hardware acceleration.
      backface-visibility: hidden;
    	will-change: all;
    	transition: opacity .25s, top .25s;
    	opacity: 1;
    	&.hidden {
      	opacity: 0;
      	// Start/end position on fade-in/out, positions centre (not top) of overlay as a percentage of hero unit height.
      	top: 80%;
    	}
      span {
        display: block;
        font-size: 1.75rem;
        font-size: 1.75rem;
      }
      > :last-child {
        margin-top: $space;
      }
      .hero-unit--logo {
        // Manually aligns logo correctly.
        margin-top: -2.25rem;
        margin-left: -0.25rem;
        font-size: 5rem;
        font-weight: bold;
        text-transform: uppercase;
        line-height: normal;
      }
      .hero-unit--subhead {
        // Manually aligns subheading (offsets line-height affecting vertical position of subhead).
        margin-top: -0.4375rem;
        margin-bottom: -0.4375rem;
        line-height: 1.5;
      }
      .hero-unit--info {
        line-height: 1.5;
        // Manually aligns bottom text correctly.
        margin-bottom: -0.125rem;
      }
    }
    .hero-unit--scroll-button {
    	position: absolute;
    	bottom: 5vh;
    	left: 50%;
    	transform: translateX(-50%);
    	will-change: all;
    	transition: opacity .4s;
    	opacity: 1;
    	&.hidden {
      	// Callback in js disables / enables pointer events after opacity transition when auto-hiding/showing on scroll position.
      	pointer-events: none;
      	opacity: 0;
    	}
    }
  }
}
// Scroll button hover effect.
.hover-enabled .hero-unit--container {
  .hero-unit--scroll-button {
    .scroll {
      will-change: all;
      transition: transform .2s;
    }
  	&:hover .scroll {
    	transform: scale(1.1);
  	}
  }
}
