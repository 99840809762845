/*  T Y P O G R A P H Y  */

body {
  font-family: 'FF DIN', 'Avenir Next', sans-serif;
  font-weight: normal;
  font-size: 1.25em;
}
h1 {
  // Parameters = font-size, font-weight, and line-height.
  @include heading-font(2em, bold, 1.5);
  text-transform: uppercase;
}
h2 {
  @include heading-font(1.2em, bold, 1.5);
  text-transform: uppercase;
}
h3 {
  @include heading-font(1em, bold, 1.5);
  text-transform: uppercase;
}
h4 {
  @include heading-font(1.2em, bold, 1.5);
}
h5 {
  @include heading-font(.625em, 400, 1.5);
}
h6 {
  @include heading-font(.625em, 400, 1.5);
}
