/*  W E B  F O N T S  */

/* This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: http://www.fontshop.com */

@font-face {
  font-family: 'FF DIN';
  src: url('../_fonts/1381398/7cec9587-8ab7-442c-941d-3361ca9b5fa0.woff2') format('woff2'),
       url('../_fonts/1381398/a5c0bb07-797b-411b-ab40-0942c7618db8.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FF DIN';
  src: url('../_fonts/1448152/673d0af4-f3a4-4a8b-8d7f-24aa5c8a4a2c.woff2') format('woff2'),
       url('../_fonts/1448152/ac55f8b3-1f99-484b-a876-7314bb62ae8d.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
