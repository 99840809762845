/*  N A V I G A T I O N  */

.navigation {
  @include user-select;
  position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
  // Smooths animation by forcing hardware acceleration (prevents navigation briefly flashing up on page reload in Vivaldi, Chrome and Opera.
	backface-visibility: hidden;
	width: 100%;
	height: 100%;   
	overflow: hidden;
	background: $black-trans;
  color: $white;
  text-transform: uppercase;
	opacity: 0;
	pointer-events: none;
	will-change: opacity;
	transition: opacity .4s;
	z-index: 10;
  &.navigation-open {
  	opacity: 1;
  	pointer-events: auto;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
  .navigation-inner {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
   	align-items: center;
   	width: 100%;
   	height: 100%;
    > ul {
      overflow-y: scroll;
      // Restores iOS momentum scrolling.
      -webkit-overflow-scrolling: touch;
      // Offsetting top margin with bottom padding allows the navigation to clear the header but still be centred vertically, without hiding any of the bottom of the nav list when in landscape mode.
      margin: $header auto 0;
      padding: 0 0 $header;
      li {
        font-size: 2.1em;
        letter-spacing: 0.075em;
        font-weight: normal;
        margin: 0.63rem 0;
        @include viewport(medium) {
          font-size: 2.5em;
          margin: 0.75rem 0;
        }
      } 
    }
  }
  a {
    outline: none;
  	text-decoration: none;
  	color: inherit;
  }
}
